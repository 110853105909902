<template>
    <!--    크리에이터 리스트 레이아웃<br>-->

    <div class="creator_search_list_wrap">
        <!--    검색 크리에이터 리스트 레이아웃-->
        <ul class="list_item_wrap">
            <li class="list_item" v-for="creator in memberData" :key="`member${creator.mb_no}`">
                <div class="curator_icon" v-if="isCurator(creator)" @click="moveDetail(creator)">{{
                        $t('en_curator')
                    }}
                </div>
                <img class="tc_swiper_slide_pic" :src="returnBackground(creator)" @click="moveDetail(creator)" alt="">
                <div class="tc_profile" @click="moveDetail(creator)">
                    <div class="tc_name">
                        <img class="tc_profile_pic2" :src="returnThumbnail(creator)" alt="">
                        <div class="tc_nickname">{{ creator.mb_nick }}</div>
                        <img class="tc_special" v-if="isSpecial(creator)" src="@/assets/image/main/special.png" alt="">
                    </div>
                </div>
                <div class="tc_curator_detail">
                    <div class="sub_wrap">
                        <div class="sub_heart" @click="likeMember(creator.mb_no)">
                            <div class="like_img" :class="{'active': isMyLike(creator)}"></div>
                            <div class="like_cnt">{{ returnLike(creator) }}</div>
                        </div>
                        <span class="sub_point" @click="moveDetail(creator)">·</span>
                        <div class="item_subject" @click="moveDetail(creator)">
                            <div class="item_inner_text">item</div>
                            <span>{{ returnItem(creator) }}</span></div>
                    </div>
                </div>
            </li>
        </ul>


        <!--    <button @click="moveDetail(`${idx}`)">유저 프로필</button>-->
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import AuthMixins from "@/mixins/AuthMixins";
import EventBus from "@/utils/event-bus";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "MemberListCreatorLayout",
    mixins: [imageOption, AuthMixins, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        moveDetail(data) {
            if (this.UserInfo.mb_no == data.mb_no) {
                this.$router.push('/myprofile')
            } else {
                this.$router.push(`/creator/${data.mb_uuid}`)
            }
        },
        returnLike(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.likeCnt)) {
                //return util.Number.numFormat(data.likeCnt)
                return data.likeCnt
            }
            return 0
        },
        returnItem(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.itemCnt)) {
                // return util.Number.numFormat(data.itemCnt);
                return data.itemCnt
            }
            return 0
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.Files) && !util.isEmpty(data.Files.Profile)) {
                return `${data.Files.Profile[0].org_url}?${this.setImageOptions(66, 66, this.returnExtension(data.Files.Profile[0].org_url))}`;
            }
        },
        returnBackground(data) {
            if (!util.isEmpty(data.Files) && !util.isEmpty(data.Files.ProfileBack)) {
                return `${data.Files.ProfileBack[0].org_url}?${this.setImageOptions(220, 140, this.returnExtension(data.Files.ProfileBack[0].org_url))}`;
            }
        },
        isCurator(data) {
            return !util.isEmpty(data) && data.g_idx == 2
        },
        isSpecial(data) {
            return !util.isEmpty(data) && data.mb_premium == 1
        },
        isMyLike(data) {
            if (!util.isEmpty(data.likeStatus)) {
                return data.likeStatus
            }

        },
        likeMember(key) {
            EventBus.$emit('likeMember', key);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
